import { template as template_b6cacec521334caabe01fe376d712f78 } from "@ember/template-compiler";
const WelcomeHeader = template_b6cacec521334caabe01fe376d712f78(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
